<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #404044;
}
body {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Medium, PingFang SC;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: #404044;
}
a:hover,
a:visited,
a:link,
a:active {
  color: #404044;
}
ul,
ol {
  list-style: none;
}

img {
  vertical-align: top;
  border: none;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #404044;
}

nav a.router-link-exact-active {
  color: #ca151a;
}
</style>
