<template>
  <div class="page-container" @scroll="page_scroll($event)">
    <!-- header -->
    <div class="page-header-container flex_between">
      <div class="header-logo-container">
        <img src="@/assets/header-logo.png" />
      </div>
      <div class="menu-container flex_start">
        <div
          class="menu-item"
          :class="{ _active: pageData.currentActiveMenu === 'main' }"
        >
          <a href="#main">首页</a>
        </div>
        <div
          class="menu-item"
          :class="{ _active: pageData.currentActiveMenu === 'serve' }"
        >
          <a href="#serve">平台服务</a>
        </div>
        <div
          class="menu-item"
          :class="{ _active: pageData.currentActiveMenu === 'vender' }"
        >
          <a href="#vender">商家入驻</a>
        </div>
      </div>
    </div>
    <!-- main -->
    <a name="main" ref="mainContainer" style="position: relative; top: 0px"></a>
    <div class="main-container">
      <div class="main-image-container">
        <img src="@/assets/main-bg.png" />
      </div>
      <div class="main-title-container">
        <div class="title-text">时尚春熙 美好锦江</div>
        <div class="subtitle-text">一站式游玩享购体验平台</div>
      </div>
      <div class="main-content-container flex_center">
        <div class="content-item">
          <div class="icon-item flex_center">
            <img src="@/assets/fulitong.png" />
          </div>
          <div class="title-text">福利通</div>
          <div class="subtitle-text">数据权益积分三通，让消费回归实惠</div>
        </div>
        <div class="content-item">
          <div class="icon-item flex_center">
            <img src="@/assets/arquwan.png" />
          </div>
          <div class="title-text">AR趣玩</div>
          <div class="subtitle-text">线上体验线下消费，“趣”玩亦“去”玩</div>
        </div>
        <div class="content-item">
          <div class="icon-item flex_center">
            <img src="@/assets/jingzhuntuisong.png" />
          </div>
          <div class="title-text">精准推荐</div>
          <div class="subtitle-text">多维度推荐，AI让人更ai</div>
        </div>
      </div>
    </div>
    <!-- serve -->
    <a
      name="serve"
      ref="serveContainer"
      style="position: relative; top: -50px"
    ></a>
    <div class="serve-container">
      <div class="title-text">即刻体验四潮专线</div>
      <div class="subtitle-text">全新体验定制化场景专线</div>
      <div class="content-container flex_center">
        <!--  -->
        <div class="content-item">
          <div class="picture-item">
            <img src="@/assets/chaoyou.png" />
          </div>
          <div class="title-container">
            <div class="title-text">潮游</div>
            <div class="subtitle-text">以鲜花爱情为主题的潮游专线</div>
          </div>
          <div class="bg-picture">
            <img src="@/assets/you.png" style="width: 70px; height: 86px" />
          </div>
        </div>
        <!--  -->
        <div class="content-item">
          <div class="bg-picture">
            <img src="@/assets/wan.png" style="width: 70px; height: 75px" />
          </div>
          <div class="picture-item">
            <img src="@/assets/chaowan.png" />
          </div>
          <div class="title-container">
            <div class="title-text">潮玩</div>
            <div class="subtitle-text">以电音电竞为主题的潮玩专线</div>
          </div>
        </div>
        <!--  -->
        <div class="content-item">
          <div class="picture-item">
            <img src="@/assets/chaoxiang.png" />
          </div>
          <div class="title-container">
            <div class="title-text">潮享</div>
            <div class="subtitle-text">以美食美容为主题的潮享专线</div>
          </div>
          <div class="bg-picture">
            <img src="@/assets/xiang.png" style="width: 61px; height: 90px" />
          </div>
        </div>
        <!--  -->
        <div class="content-item">
          <div class="picture-item">
            <img src="@/assets/chaogou.png" />
          </div>
          <div class="title-container">
            <div class="title-text">潮购</div>
            <div class="subtitle-text">以品牌时尚为主题的潮购专线</div>
          </div>
          <div class="bg-picture">
            <img src="@/assets/gou.png" style="width: 70px; height: 87px" />
          </div>
        </div>
      </div>
    </div>
    <!-- vender -->
    <a
      name="vender"
      ref="venderContainer"
      style="position: relative; top: -50px"
    ></a>
    <div class="vender-container">
      <div class="vender-container-footer-bg">
        <img src="@/assets/page-fotter-bg.png" style="width: 100%" />
      </div>
      <div class="title-text">商家入驻</div>
      <div class="subtitle-text">为商家提供简洁高效的经营体验</div>
      <div class="vender-content-container flex_center">
        <!--  -->
        <div class="content-item">
          <div class="icon-item">
            <img src="@/assets/xianshangshangpuzhanshi.png" />
          </div>
          <div class="title-text">线上商铺展示</div>
          <div class="subtitle-text">快速搭建个性化线上商铺</div>
        </div>
        <!--  -->
        <div class="content-item">
          <div class="icon-item">
            <img src="@/assets/xianshangshangpuzhanshi.png" />
          </div>
          <div class="title-text">智能化运营</div>
          <div class="subtitle-text">
            多维度数据分析及时掌握<br />商铺运营情况
          </div>
        </div>
        <!--  -->
        <div class="content-item">
          <div class="icon-item">
            <img src="@/assets/xianshangshangpuzhanshi.png" />
          </div>
          <div class="title-text">精细化运营会员</div>
          <div class="subtitle-text">
            提供全域+跨域+私域多维度<br />会员流量运营
          </div>
        </div>
        <!--  -->
        <div class="content-item">
          <div class="icon-item">
            <img src="@/assets/xianshangshangpuzhanshi.png" />
          </div>
          <div class="title-text">商企服务</div>
          <div class="subtitle-text">云上创业服务、赋能商企运营</div>
        </div>
      </div>
      <div class="title-text">更多方式关注时尚春熙</div>
      <div class="download-qr-code-container flex_center">
        <div class="content-item">
          <div class="qr-code-item">
            <img src="@/assets/yonghuduan.png" />
          </div>
          <div class="title-text">扫描二维码下载用户端</div>
        </div>
        <div class="content-item">
          <div class="qr-code-item">
            <img src="@/assets/shanghuduan.png" />
          </div>
          <div class="title-text">扫描二维码下载商户端</div>
        </div>
      </div>
    </div>
    <div class="footer-container flex_center">
      <div>
        <div>Copyright © 2023 成都万花春熙科技有限公司 蜀ICP备2024093442号</div>
        <!-- <div>
          <a href="https://beian.miit.gov.cn" target="_blank"
            >蜀ICP备2021024446号</a
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from "@vue/reactivity";

const pageData = reactive({
  currentActiveMenu: "main",
});
const mainContainer = ref<any>(null);
const serveContainer = ref<any>(null);
const venderContainer = ref<any>(null);

function page_scroll(event: any) {
  // console.log(event.target.scrollTop);
  if (event.target.scrollTop < serveContainer.value.offsetTop - 100) {
    pageData.currentActiveMenu = "main";
  } else if (event.target.scrollTop < venderContainer.value.offsetTop - 100) {
    pageData.currentActiveMenu = "serve";
  } else {
    pageData.currentActiveMenu = "vender";
  }
  // console.log(mainContainer.value.offsetTop);
  // console.log(serveContainer.value.offsetTop);
  // console.log(venderContainer.value.offsetTop);
}
</script>
<style scoped>
.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-container {
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  overflow: auto;
  padding-top: 64px;
}
.page-header-container {
  width: 100%;
  padding: 12px 18% 8px;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 800;
}
.header-logo-container {
  height: 44px;
}
.header-logo-container img {
  height: 40px;
}

.menu-container {
  padding: 0 20px;
  height: 44px;
  font-size: 16px;
}
.menu-container .menu-item {
  line-height: 22px;
  padding-bottom: 4px;
}
.menu-container .menu-item._active {
  border-bottom: 3px solid #ca151a;
  color: #ca151a;
  font-weight: 500;
}
.menu-container .menu-item:not(:first-child) {
  margin-left: 40px;
}
/* .menu-container .menu-item a {

} */

.main-container {
  /* height: 835px; */
  padding-bottom: 140px;
  background: #f9f9f9;
  width: 100%;
  position: relative;
}
.main-image-container img {
  width: 100%;
  height: 100%;
}

.main-container .main-title-container {
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
}

.main-container .main-title-container > .title-text {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.main-container .main-title-container > .subtitle-text {
  margin-top: 20px;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.main-content-container {
  width: 100%;
  position: absolute;
  bottom: 45px;
}
.main-content-container .content-item {
  padding: 35px 8px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 385px;
  text-align: center;
}
.main-content-container .content-item:not(:first-child) {
  margin-left: 25px;
}
.main-content-container .content-item .icon-item img {
  height: 50px;
}
.main-content-container .content-item > .title-text {
  margin-top: 25px;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}
.main-content-container .content-item > .subtitle-text {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #808080;
}

.serve-container {
  width: 100%;
  padding: 50px 0 100px;
  text-align: center;
}

.serve-container > .title-text {
  font-size: 48px;
  color: #404044;
  line-height: 48px;
  font-weight: 400;
}
.serve-container > .subtitle-text {
  font-size: 24px;
  color: #404044;
  line-height: 24px;
  font-weight: 400;
  color: #808080;
  margin-top: 20px;
}
.serve-container > .content-container {
  margin-top: 45px;
}
.serve-container > .content-container > .content-item {
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  width: 270px;
  margin: 0 20px;
  position: relative;
  overflow: hidden;
}
.serve-container > .content-container > .content-item > .picture-item {
  height: 250px;
  overflow: hidden;
}
.serve-container > .content-container > .content-item > .picture-item img {
  width: 270px;
}
.serve-container > .content-container > .content-item > .title-container {
  padding: 25px 0px 25px 25px;
  text-align: left;
  position: relative;
  z-index: 2;
}
.serve-container
  > .content-container
  > .content-item
  > .title-container
  > .title-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #404044;
}

.serve-container
  > .content-container
  > .content-item
  > .title-container
  > .subtitle-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #808080;
  margin-top: 15px;
}

.serve-container .content-item .bg-picture {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

/*  */
.vender-container {
  padding-top: 100px;
  background: #fff;
  padding-bottom: 250px;
  position: relative;
}
.vender-container-footer-bg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.vender-container > .title-text {
  font-size: 48px;
  line-height: 48px;
}

.vender-container > .subtitle-text {
  font-size: 20px;
  line-height: 20px;
  color: #808080;
  margin-top: 20px;
}
.vender-container .vender-content-container {
  margin-top: 60px;
  width: 100%;
  text-align: center;
  margin-bottom: 130px;
}

.vender-container .vender-content-container > .content-item {
  margin: 0 40px;
  width: 210px;
}

.vender-container .vender-content-container > .content-item > .icon-item img {
  width: 60px;
  height: 60px;
}

.vender-container .vender-content-container > .content-item > .title-text {
  margin-top: 30px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}
.vender-container .vender-content-container > .content-item > .subtitle-text {
  margin-top: 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #808080;
}
.vender-container .download-qr-code-container {
  margin-top: 50px;
}
.vender-container .download-qr-code-container > .content-item {
  margin: 0 55px;
}
.vender-container .download-qr-code-container > .content-item .qr-code-item {
  background: fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 150px;
  height: 150px;
  padding: 10px;
}
.vender-container
  .download-qr-code-container
  > .content-item
  .qr-code-item
  img {
  width: 100%;
  height: 100%;
}
.vender-container .download-qr-code-container > .content-item > .title-text {
  color: #808080;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
}
.footer-container {
  height: 80px;
  background: #000;
  font-size: 12px;
  color: #999;
}
.footer-container  a {
    color: #999;
  }
</style>
